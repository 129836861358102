.loaderWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    &.fillScreen {
        inset: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        position: fixed;
    }

    img {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
