const initialState = {
    email: '',
};

const resetPwReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EMAIL_SENT':
            return {
                email: action.payload,
            };
        default:
            return state;
    }
};

export default resetPwReducer;
