const initialState = [];

const breadcrumbsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'BREADCRUMBS':
            return action.payload;
        default:
            return state;
    }
};

export default breadcrumbsReducer;
