const initialState = {
    crms: false,
    hrm: false,
    scms: false,
};

const productStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PRODUCTS_STATUS':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default productStatusReducer;
