const initialState = {
    user: {
        created: undefined,
        user_id: '',
        origin_site: '',
        verified: false,
        fullname: '',
        email: '',
        pw_exists: true,
    },
    LoginAsU: false,
};

const adminDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADMIN_LOGIN':
            return {
                user: action.payload.user,
                LoginAsU: action.payload.LoginAsU,
            };
        case 'ADMIN_LOGOUT':
            return {
                user: action.payload.user,
                LoginAsU: action.payload.LoginAsU,
            };
        default:
            return state;
    }
};

export default adminDataReducer;
