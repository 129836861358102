const initialState = {
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    calMethod: 'KARACHI',
    asrJuristics: 'HANAFI',
};

const prayerTimeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_YEAR':
            return { ...state, selectedYear: action.payload };
        case 'SET_SELECTED_MONTH':
            return { ...state, selectedMonth: action.payload };
        case 'SET_CAL_METHOD':
            return { ...state, calMethod: action.payload };
        case 'SET_ASR_JURISTICS':
            return { ...state, asrJuristics: action.payload };
        default:
            return state;
    }
};

export default prayerTimeReducer;
