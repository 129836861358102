const initialState = {
    id: '',
    acct_email: '',
    integ_name: '',
    created: '',
    acct_name: '',
    user_id: '',
};

const integDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INTEGDATA':
            return action.payload;
        default:
            return state;
    }
};

export default integDataReducer;
