/***************************************************************
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 *                                                             *
 * This was generated by `be/file_api/codes-sync.sh`.          *
 *                                                             *
 * Please edit `be/file_api/codes.txt` and run the sync script.*
 *                                                             *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT   *
 ***************************************************************/

const fileApiHttpCodes = {
    // Reserved
    RC_E_NO_BODY: 2999,
    RC_E_MALFORMED: 2998,
    RC_E_RATELIMIT: 2997,

    // POST /file
    RC_FILE_UPLOAD: 1000,

    // GET /_/file/exists
    RC_FILE_EXISTS: 1000,

    // DELETE /_/file
    RC_FILE_DELETE: 1000,
};

declare global {
    var fileHttpCodes: typeof fileApiHttpCodes;
}

globalThis.fileHttpCodes = fileApiHttpCodes;

export default fileHttpCodes;
