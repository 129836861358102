const initialState = {
    image: '',
};

const companyDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EDIT_COMPANY_IMG':
            return {
                image: action.payload,
            };
        default:
            return state;
    }
};

export default companyDataReducer;
