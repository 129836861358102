const initialState = {
    credits: 0,
};

const totalCreditsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREDITS':
            return {
                credits: action.payload,
            };
        case 'ADD':
            return {
                ...state,
                credits: state.credits + action.payload,
            };
        case 'SUB':
            return {
                ...state,
                credits: state.credits - action.payload,
            };
        default:
            return state;
    }
};

export default totalCreditsReducer;
