import { FC, ReactNode } from 'react';

import Loading from 'dsl_web/components/Loading.tsx';

import * as s from 'dsl_web/components/Loader.module.scss';

interface LoaderProps {
    imgSrc?: string;
    fillScreen?: boolean;
    loaderProps?: {
        scale?: number;
        borderScale?: number;
    };
    children?: ReactNode;
}

const Loader: FC<LoaderProps> = ({ imgSrc, fillScreen, loaderProps, children, ...rest }) => {
    return (
        <div className={`${s.loaderWrapper} ${fillScreen && s.fillScreen}`} {...rest}>
            <div style={{ margin: 'auto' }}>
                <div style={{ margin: 'auto', position: 'relative' }}>
                    <Loading
                        {...loaderProps}
                        scale={loaderProps?.scale || 10}
                        borderScale={loaderProps?.borderScale || 5}
                    />
                </div>
                {children}
            </div>
        </div>
    );
};

export default Loader;
