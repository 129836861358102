const initialState = {
    token: '',
};

const userTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'VERIFY_SIGNUP':
            return {
                token: action.payload,
            };
        default:
            return state;
    }
};

export default userTokenReducer;
