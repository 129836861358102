const initialState = true;

const cookieReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COOKIE':
            return action.payload;
        default:
            return state;
    }
};

export default cookieReducer;
