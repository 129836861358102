const initialState = {
    created: undefined,
    user_id: '',
    origin_site: '',
    verified: false,
    fullname: '',
    email: '',
    pw_exists: true,
};

const userDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return action.payload;
        case 'SIGNUP':
            return action.payload;
        case 'EDIT_NAME':
            return {
                ...state,
                fullname: action.payload,
            };
        case 'EDIT_EMAIL':
            return {
                ...state,
                email: action.payload,
            };
        case 'EDIT_PASS':
            return {
                ...state,
                pw_exists: action.payload,
            };
        case 'EDIT_PROFILE_IMG':
            return {
                ...state,
                profile_img_url: action.payload,
            };
        default:
            return state;
    }
};

export default userDataReducer;
