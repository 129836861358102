import { combineReducers } from 'redux';

import adminDataReducer from 'web/mslm/cloud/redux/reducers/adminData.ts';
import userDataReducer from 'web/mslm/cloud/redux/reducers/userData.ts';
import companyDataReducer from 'web/mslm/cloud/redux/reducers/companyData.ts';
import resetPwReducer from 'web/mslm/cloud/redux/reducers/resetPw.ts';
import userTokenReducer from 'web/mslm/cloud/redux/reducers/userToken.ts';
import totalCreditsReducer from 'web/mslm/cloud/redux/reducers/totalCredits.ts';
import integDataReducer from 'web/mslm/cloud/redux/reducers/integData.ts';
import integHistoryReducer from 'web/mslm/cloud/redux/reducers/integHistory.ts';
import sidebarReducer from 'web/mslm/cloud/redux/reducers/sidebar.ts';
import titleReducer from 'web/mslm/cloud/redux/reducers/pageTitle.ts';
import breadcrumbsReducer from 'web/mslm/cloud/redux/reducers/breadcrumbs.ts';
import cookieReducer from 'web/mslm/cloud/redux/reducers/cookie.ts';
import prayerTimeReducer from 'web/mslm/cloud/redux/reducers/prayerTime.ts';
import productStatusReducer from 'web/mslm/cloud/redux/reducers/productStatus.ts';

const rootReducer = combineReducers({
    adminData: adminDataReducer,
    userData: userDataReducer,
    companyData: companyDataReducer,
    resetPw: resetPwReducer,
    userToken: userTokenReducer,
    totalCredits: totalCreditsReducer,
    integData: integDataReducer,
    integHistory: integHistoryReducer,
    isSidebarOpen: sidebarReducer,
    pageTitle: titleReducer,
    pageHierarchy: breadcrumbsReducer,
    cookie: cookieReducer,
    prayerTime: prayerTimeReducer,
    productsStatus: productStatusReducer,
});

export default rootReducer;
