/*************************************************************
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 *                                                           *
 * This was generated by `be/fe_api/codes-sync.sh`.          *
 *                                                           *
 * Please edit `be/fe_api/codes.txt` and run the sync        *
 * script.                                                   *
 *                                                           *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 * DO NOT EDIT            DO NOT EDIT            DO NOT EDIT *
 *************************************************************/

const svHttpCodes = {
    // Reserved
    RC_E_NO_BODY: 2999,
    RC_E_MALFORMED: 2998,
    RC_E_RATELIMIT: 2997,
    RC_E_MCLOUD_ACCT_NOT_VERIFIED: 2996,
    RC_E_JMT_M_EXISTS: 2995,
    RC_E_HRMS_DOES_NOT_EXISTS: 2993,
    RC_E_EMAIL_VERIFY_LIMIT_REACHED: 2994,
    RC_E_CRMS_DOES_NOT_EXISTS: 2992,
    RC_E_CRMS_INVALID_SERVICE_ID: 2991,
    RC_E_CRMS_SMS_OTP_NOT_ENABLED: 2990,
    RC_E_CRMS_INVALID_FIELD_TYPE: 2989,
    RC_E_CRMS_INVALID_OBJECT_TYPE: 2988,
    RC_E_CRMS_FIELD_NOT_LINKED_WITH_CRMS: 2987,
    RC_E_CRMS_INVALID_FIELD_CONSTRAINTS: 2986,
    RC_E_CRMS_INVALID_FIELD_CONSTRAINTS_ORDER: 2985,
    RC_E_CRMS_INVALID_FIELD_VALUE: 2984,
    RC_E_CRMS_FIELD_NAME_ALREADY_EXISTS: 2983,
    RC_E_CRMS_INVALID_FIELD_ID: 2982,
    RC_E_CRMS_INVALID_FIELD_NAME: 2981,

    // POST /_/api/u/v1/signup
    RC_U_SIGNUP: 1000,
    RC_E_U_SIGNUP_BAD_ORIGIN_SITE: 2000,
    RC_E_U_SIGNUP_BAD_FULLNAME: 2001,
    RC_E_U_SIGNUP_MIN_FULLNAME: 2002,
    RC_E_U_SIGNUP_MAX_FULLNAME: 2003,
    RC_E_U_SIGNUP_PW_WEAK: 2004,
    RC_E_U_SIGNUP_BAD_GENDER: 2008,
    RC_E_U_SIGNUP_BAD_DOB: 2009,
    RC_E_U_SIGNUP_EMAIL_BAD_GENERIC: 2011,
    RC_E_U_SIGNUP_EMAIL_BAD_SYNTAX: 2012,
    RC_E_U_SIGNUP_EMAIL_NO_MAILBOX: 2013,
    RC_E_U_SIGNUP_EMAIL_DISPOSABLE: 2014,
    RC_E_U_SIGNUP_EMAIL_OR_PHONE_EXISTS: 2015,
    RC_E_U_SIGNUP_INVALID_PLATFORM: 2016,
    RC_E_U_SIGNUP_INVITEE_EMAIL_NOT_EXISTS: 2017,
    RC_E_U_SIGNUP_INVALID_AUTO_TOKEN: 2018,

    // POST /_/api/u/v1/set_profile_img
    RC_U_SET_PROFILE_IMG: 1000,
    RC_E_U_SET_PROFILE_IMG_METADATA_NOT_EXISTS: 2000,

    // GET /_/api/u/v1/oauth2_login_url
    RC_U_OAUTH2_LOGIN_URL: 1000,

    // POST /_/api/u/v1/oauth2_login
    RC_U_OAUTH2_LOGIN: 1000,
    RC_E_U_OAUTH2_LOGIN_ACCESS_TOKEN: 2000,
    RC_E_U_OAUTH2_LOGIN_USER_INFO: 2001,

    // POST /_/api/u/v1/resend_verification
    RC_U_RESEND_VERIFICATION: 1000,
    RC_E_U_RESEND_VERIFICATION_ALREADY_VERIFIED: 2000,

    // POST /_/api/u/v1/email_signup_verify
    RC_U_EMAIL_SIGNUP_VERIFY: 1000,
    RC_E_U_EMAIL_SIGNUP_VERIFY_FAILED: 2000,

    // POST /_/api/u/v1/phone_signup_verify
    RC_U_PHONE_SIGNUP_VERIFY: 1000,
    RC_E_U_PHONE_SIGNUP_VERIFY_FAILED: 2000,

    // POST /_/api/u/v1/login
    RC_U_LOGIN: 1000,
    RC_E_U_LOGIN_NOT_FOUND: 2000,
    RC_E_U_LOGIN_BAD_LOGIN_TYPE: 2001,
    RC_E_U_LOGIN_NO_PW: 2002,
    RC_E_U_LOGIN_INACTIVE: 2003,
    RC_E_U_LOGIN_VERIFY_FAILED: 2004,
    RC_E_U_LOGIN_BANNED: 2005,
    RC_E_U_LOGIN_INVALID_PLATFORM: 2006,

    // GET /_/api/u/v1/logout
    RC_U_LOGOUT: 1000,
    RC_E_U_NOT_LOGIN: 2000,

    // GET /_/api/u/v1/signup/auto
    RC_U_SIGNUP_AUTO: 1000,

    // POST /_/api/u/v1/signup/auto/init
    RC_U_SIGNUP_AUTO_INIT: 1000,

    // GET /_/api/u/v1/signup/auto/check
    RC_U_SIGNUP_AUTO_CHECK: 1000,
    RC_E_U_SIGNUP_AUTO_CHECK_NOT_VERIFIED: 2000,

    // GET /_/api/u/v1/details
    RC_U_DETAILS: 1000,
    RC_E_U_DETAILS_NOT_FOUND: 2000,

    // GET /_/api/u/v1/notifs/list?after=<dt>&before=<dt>
    RC_U_NOTIFS_LIST: 1000,
    RC_E_U_NOTIFS_LIST_GENERIC: 2000,

    // POST /_/api/u/v1/notifs/mark
    RC_U_NOTIFS_MARK: 1000,

    // POST /_/api/u/v1/notifs/delete
    RC_U_NOTIFS_DELETE: 1000,

    // POST /_/api/u/v1/email_change
    RC_U_EMAIL_CHANGE: 1000,
    RC_E_U_EMAIL_CHANGE_EMAIL_EXISTS: 2000,
    RC_E_U_EMAIL_CHANGE_EMAIL_BAD_GENERIC: 2001,
    RC_E_U_EMAIL_CHANGE_EMAIL_BAD_SYNTAX: 2002,
    RC_E_U_EMAIL_CHANGE_EMAIL_NO_MAILBOX: 2003,
    RC_E_U_EMAIL_CHANGE_EMAIL_DISPOSABLE: 2004,
    RC_E_U_EMAIL_CHANGE_EMAIL_NOT_FOUND: 2005,

    // POST /_/api/u/v1/phone_change
    RC_U_PHONE_CHANGE: 1000,
    RC_E_U_PHONE_CHANGE_PHONE_EXISTS: 2000,

    // POST /_/api/u/v1/email_change/verify
    RC_U_EMAIL_CHANGE_VERIFY: 1000,
    RC_E_U_EMAIL_VERIFY_EMAIL_TAKEN: 2000,

    // POST /_/api/u/v1/phone_change/verify
    RC_U_PHONE_CHANGE_VERIFY: 1000,
    RC_E_U_PHONE_VERIFY_PHONE_TAKEN: 2000,

    // POST /_/api/u/v1/confirm_pw
    RC_U_CONFIRM_PW: 1000,
    RC_E_U_CONFIRM_PW_INVALID: 2000,

    // POST /_/api/u/v1/fullname_change
    RC_U_FULLNAME_CHANGE: 1000,
    RC_E_U_FULLNAME_CHANGE_RATELIMIT: 2000,
    RC_E_U_FULLNAME_CHANGE_MIN: 2001,
    RC_E_U_FULLNAME_CHANGE_MAX: 2002,
    RC_E_U_FULLNAME_CHANGE_BAD: 2003,
    RC_E_U_FULLNAME_CHANGE_NOT_FOUND: 2004,

    // POST /_/api/u/v1/pw_change
    RC_U_PW_CHANGE: 1000,
    RC_E_U_PW_CHANGE_INVALID_OLD_PW: 2000,
    RC_E_U_PW_CHANGE_NEW_PW_WEAK: 2001,
    RC_E_U_PW_CHANGE_RATELIMIT: 2002,
    RC_E_U_PW_CHANGE_NO_USER: 2003,

    // GET /_/api/u/v1/auditlog/list?after=<dt>&before=<dt>
    RC_U_AUDITLOG_LIST: 1000,
    RC_E_U_AUDITLOG_LIST_GENERIC: 2000,

    // POST /_/api/u/v1/deactivate
    RC_U_DEACTIVATE: 1000,
    RC_E_U_DEACTIVATE_GENERIC: 2000,

    // DELETE /_/api/u/v1/delete
    RC_U_DELETE: 1000,
    RC_E_U_DELETE_NOT_OWNER: 2000,

    // POST /_/api/u/v1/pw_reset_email
    RC_U_PW_RESET_EMAIL: 1000,
    RC_E_U_PW_RESET_EMAIL_RATELIMIT: 2000,

    // POST /_/api/u/v1/pw_reset_phone
    RC_U_PW_RESET_PHONE: 1000,

    // POST /_/api/u/v1/pw_reset_email/verify
    RC_U_PW_RESET_EMAIL_VERIFY: 1000,
    RC_E_U_PW_RESET_EMAIL_VERIFY_EXPIRED: 2000,

    // POST /_/api/u/v1/pw_reset_phone/verify
    RC_U_PW_RESET_PHONE_VERIFY: 1000,
    RC_E_U_PW_RESET_PHONE_VERIFY_EXPIRED: 2000,
    RC_E_U_PW_RESET_PHONE_VERIFY_PW_WEAK: 2001,
    RC_E_U_PW_RESET_PHONE_VERIFY_INVALID_OTP: 2001,

    // POST /_/api/mcloud/v1/acct_invite
    RC_MCLOUD_ACCT_INVITE: 1000,

    // POST /_/api/mcloud/v1/invite_details
    RC_MCLOUD_ACCT_INVITE_DETAILS: 1000,

    // POST /_/api/mcloud/v1/acct_invite_accept
    RC_MCLOUD_ACCT_INVITE_ACCEPT: 1000,
    RC_E_MCLOUD_ACCT_INVITE_ACCEPT_INVITE_NOT_EXISTS: 2000,
    RC_E_MCLOUD_ACCT_INVITE_ACCEPT_USER_NOT_EXISTS: 2001,

    // POST /_/api/mcloud/v1/acct_invite_reject
    RC_MCLOUD_ACCT_INVITE_REJECT: 1000,
    RC_E_MCLOUD_ACCT_INVITE_REJECT_INVITE_NOT_EXISTS: 2000,

    // DELETE /_/api/u/v1/invite_delete
    RC_U_INVITE_DELETE: 1000,

    // POST /_/api/u/v1/invite_signup
    RC_U_INVITE_SIGNUP: 1000,
    RC_E_U_INVITE_SIGNUP_INVITE_NOT_EXISTS: 2000,
    RC_E_U_INVITE_SIGNUP_INVITE_INACTIVE: 2001,

    // GET /_/api/mcloud/v1/invite_valid
    RC_MCLOUD_INVITE_VALID: 1000,
    RC_E_MCLOUD_INVITE_ALREADY_EXISTS: 1001,
    RC_E_MCLOUD_ALREADY_EXISTS: 1002,
    RC_E_MCLOUD_SELF_INVITE: 1003,

    // GET /_/api/u/v1/location
    RC_U_LOCATION: 1000,

    // POST /_/api/mslm/dev/v1/portfolio_dl
    RC_MSLM_DEV_PORTFOLIO_DOWNLOAD: 1000,
    RC_E_MSLM_DEV_PORTFOLIO_DOWNLOAD: 2000,

    // POST /_/api/billing/v1/stripe/setup_intent/create
    RC_BILLING_STRIPE_SI_CREATE: 1000,
    RC_E_BILLING_STRIPE_SI_CREATE_GENERIC: 2000,

    // POST /_/api/billing/v1/stripe/payment_intent/create
    RC_BILLING_STRIPE_PI_CREATE: 1000,
    RC_E_BILLING_STRIPE_PI_CREATE_INVALID_PAYMENT_TITLE: 2000,
    RC_E_BILLING_STRIPE_PI_CREATE_GENERIC: 2001,

    // GET /_/api/billing/v1/pm_await?pm_id=<pm_id>
    RC_BILLING_PM_AWAIT: 1000,
    RC_E_BILLING_PM_AWAIT_GENERIC: 2000,
    RC_E_BILLING_PM_AWAIT_TIMEOUT: 2001,
    RC_E_BILLING_PM_CARD_NOT_FOUND: 2002,

    // GET /_/api/billing/v1/payment_await?payment_id=<payment_id>
    RC_BILLING_PAYMENT_AWAIT: 1000,
    RC_E_BILLING_PAYMENT_AWAIT_GENERIC: 2000,
    RC_E_BILLING_PAYMENT_AWAIT_TIMEOUT: 2001,
    RC_E_BILLING_PAYMENT_NOT_FOUND: 2002,

    // GET /_/api/billing/v1/profile/list
    RC_BILLING_PROFILE_LIST: 1000,
    RC_E_BILLING_PROFILE_LIST_GENERIC: 2000,

    // DELETE /_/api/billing/v1/profile/del?uuid=<uuid>
    RC_BILLING_PROFILE_DEL: 1000,
    RC_E_BILLING_PROFILE_DEL_GENERIC: 2000,
    RC_E_BILLING_PROFILE_DEL_NOT_FOUND: 2001,
    RC_E_BILLING_PROFILE_DEL_DEFAULT_PROFILE: 2002,

    // POST /_/api/billing/v1/profile/set_default?uuid=<uuid>
    RC_BILLING_PROFILE_SET_DEFAULT: 1000,
    RC_E_BILLING_PROFILE_SET_DEFAULT_GENERIC: 2000,
    RC_E_BILLING_PROFILE_SET_DEFAULT_NOT_FOUND: 2001,

    // POST /_/api/billing/v1/sub/create
    RC_BILLING_SUB_CREATE: 1000,
    RC_E_BILLING_SUB_CREATE_ALREADY_EXIST: 2000,
    RC_E_BILLING_SUB_CREATE_NO_PAYMENT: 2001,

    // POST /_/api/billing/v1/addons/create
    RC_BILLING_ADDONS_CREATE: 1000,
    RC_E_BILLING_ADDONS_CREATE_INVALID_PRODUCT: 2000,
    RC_E_BILLING_ADDONS_CREATE_NOT_FOUND: 2001,
    RC_E_BILLING_ADDONS_CREATE_NO_PAYMENT_METHOD: 2002,

    // POST /_/api/billing/v1/addons/del?product_name=<product_name>
    RC_BILLING_ADDONS_DEL: 1000,
    RC_E_BILLING_ADDONS_DEL_INVALID_PRODUCT: 2000,
    RC_E_BILLING_ADDONS_DEL_NOT_FOUND: 2001,

    // POST /_/api/billing/v1/sub/update
    RC_BILLING_SUB_UPDATE: 1000,
    RC_E_BILLING_SUB_UPDATE_NOT_FOUND: 2000,
    RC_E_BILLING_SUB_UPDATE_NO_PAYMENT: 2001,
    RC_E_BILLING_SUB_UPDATE_ASSET_LIMIT_EXCEEDED: 2002,
    RC_E_BILLING_SUB_UPDATE_EMP_LIMIT_EXCEEDED: 2003,

    // GET /_/api/billing/v1/pricing
    RC_BILLING_PRICING: 1000,

    // GET /_/api/billing/v1/invoice/list
    RC_BILLING_INVOICE_LIST: 1000,
    RC_E_BILLING_INVOICE_LIST_DEFAULT_GENERIC: 2000,

    // GET /_/api/billing/v1/invoice/download?invoice_id=<uuid>
    RC_BILLING_INVOICE_DOWNLOAD: 1000,

    // GET /_/api/billing/v1/invoice/detail?invoice_id=<uuid>
    RC_BILLING_INVOICE_DETAIL: 1000,

    // GET /_/api/billing/v1/ewallet/credits
    RC_BILLING_EWALLET_CREDITS: 1000,
    RC_E_BILLING_EWALLET_CREDITS_GENERIC: 2000,

    // GET /_/api/billing/v1/analytics
    RC_BILLING_ANALYTICS: 1000,
    RC_E_BILLING_ANALYTICS_GENERIC: 2000,

    // POST /_/api/emailv/v1/bv/create
    RC_EMAILV_BV_CREATE: 1000,

    // POST /_/api/emailv/v1/bv/process
    RC_E_EMAILV_BV_PROCESS_LIMIT_EXCEEDED: 2000,
    RC_E_EMAILV_BV_PROCESS_NO_EMAIL: 2001,

    // POST /_/api/emailv/v1/bv/cloud_upload
    RC_EMAILV_BV_CLOUD_UPLOAD: 1000,

    // POST /_/api/emailv/v1/bv/pause
    RC_EMAILV_BV_PAUSE: 1000,

    // POST /_/api/emailv/v1/bv/play
    RC_EMAILV_BV_PLAY: 1000,

    // GET /_/api/emailv/v1/bv/status?uuid=<uuid>
    RC_EMAILV_BV_STATUS: 1000,

    // POST /_/api/emailv/v1/bv/download?uuid=<uuid>
    RC_EMAILV_BV_DL: 1000,

    // GET /_/api/emailv/v1/bv/detail?uuid=<uuid>
    RC_EMAILV_BV_DETAIL: 1000,

    // GET /_/api/emailv/v1/bv/list?after=<dt>&before=<dt>
    RC_EMAILV_BV_LIST: 1000,

    // GET /_/api/emailv/v1/stat
    RC_EMAILV_STAT: 1000,

    // GET /_/api/emailv/v1/bv/search?q=<query>
    RC_EMAILV_BV_SEARCH: 1000,

    // DELETE /_/api/emailv/v1/bv?uuid=<uuid>
    RC_EMAILV_BV_DEL: 1000,

    // GET /_/api/adm/v1/iam/u/list
    RC_ADM_IAM_U_LIST: 1000,

    // GET /_/api/adm/v1/iam/u/info
    RC_ADM_IAM_U_INFO: 1000,

    // POST /_/api/adm/v1/iam/u/ban
    RC_ADM_IAM_U_BAN: 1000,

    // GET /_/api/adm/v1/iam/u/search
    RC_ADM_IAM_U_SEARCH: 1000,

    // GET /_/api/adm/v1/jmt/m/list
    RC_ADM_JMT_M_LIST: 1000,

    // GET /_/api/adm/v1/jmt/m/detail?uuid=<uuid>
    RC_ADM_JMT_M_DETAIL: 1000,

    // GET /_/api/adm/v1/jmt/m/auditlog_list
    RC_ADM_JMT_M_AUDITLOG_LIST: 1000,

    // POST /_/api/adm/v1/jmt/m/iqama_upd
    RC_ADM_JMT_M_IQAMA_UPD: 1000,
    RC_E_ADM_JMT_M_IQAMA_UPD_NOT_REG: 2000,

    // POST /_/api/adm/v1/jmt/m/metadata_upd
    RC_ADM_JMT_M_METADATA_UPD: 1000,

    // GET /_/api/adm/v1/jmt/m/search
    RC_ADM_JMT_M_SEARCH: 1000,

    // POST /_/api/adm/v1/jmt/m/adm_add
    RC_ADM_JMT_M_ADM_ADD: 1000,
    RC_E_ADM_JMT_M_ADM_ADD_EXISTS: 2000,

    // POST /_/api/adm/v1/jmt/m/profile_upd
    RC_ADM_JMT_M_PROFILE_UPD: 1000,

    // GET /_/api/jmt/v1/dua/category
    RC_JMT_DUA_CATEGORY: 1000,

    // POST /_/api/adm/v1/jmt/dua/category/insert
    RC_ADM_JMT_DUA_CATEGORY_INSERT: 1000,
    RC_E_ADM_JMT_DUA_CATEGORY_INSERT_ALREADY_EXIST: 2000,

    // POST /_/api/adm/v1/jmt/dua/category/update
    RC_ADM_JMT_DUA_CATEGORY_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/dua/category/delete
    RC_ADM_JMT_DUA_CATEGORY_DELETE: 1000,

    // GET /_/api/jmt/v1/dua/chapter
    RC_JMT_DUA_CHAPTER: 1000,

    // GET /_/api/jmt/v1/dua/chapter/details
    RC_JMT_DUA_CHAPTER_DETAILS: 1000,

    // POST /_/api/adm/v1/jmt/dua/chapter/insert
    RC_ADM_JMT_DUA_CHAPTER_INSERT: 1000,
    RC_E_ADM_JMT_DUA_CHAPTER_INSERT_ALREADY_EXIST: 2000,

    // POST /_/api/adm/v1/jmt/dua/chapter/update
    RC_ADM_JMT_DUA_CHAPTER_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/dua/chapter/delete
    RC_ADM_JMT_DUA_CHAPTER_DELETE: 1000,

    // GET /_/api/jmt/v1/dua
    RC_JMT_DUA: 1000,

    // GET /_/api/jmt/v1/dua/details
    RC_JMT_DUA_DETAILS: 1000,

    // GET /_/api/jmt/v1/dua/random/list
    RC_JMT_RANDOM_DUA_LIST: 1000,

    // POST /_/dua/adm/v1/jmt/dua/update
    RC_ADM_JMT_DUA_UPDATE: 1000,

    // POST /_/dua/adm/v1/jmt/dua/insert
    RC_ADM_JMT_DUA_INSERT: 1000,

    // DELETE /_/api/adm/v1/jmt/dua/delete
    RC_ADM_JMT_DUA_DELETE: 1000,

    // POST /_/api/adm/v1/jmt/m/adm_del
    RC_ADM_JMT_M_ADM_DEL: 1000,

    // POST /_/api/adm/v1/jmt/m/add
    RC_ADM_JMT_M_ADD: 1000,
    RC_E_ADM_JMT_M_ADD_REG_NO_ID: 2000,
    RC_E_ADM_JMT_M_ADD_REG_U_NOT_EXIST: 2001,

    // POST /_/api/adm/v1/jmt/m/adm_upd
    RC_ADM_JMT_M_ADM_UPD: 1000,
    RC_E_ADM_JMT_M_ADM_UPD_U_NOT_EXIST: 2001,

    // POST /_/api/adm/v1/jmt/m/del
    RC_ADM_JMT_M_DEL: 1000,

    // POST /_/api/adm/v1/jmt/m/reg
    RC_ADM_JMT_M_REG: 1000,
    RC_E_ADM_JMT_M_REG_NO_ID: 2000,
    RC_E_ADM_JMT_M_REG_U_NOT_EXIST: 2001,

    // POST /_/api/adm/v1/jmt/m/onboard
    RC_ADM_JMT_M_ONBOARD: 1000,
    RC_E_ADM_JMT_M_ONBOARD_ALREADY_REG: 2000,
    RC_E_ADM_JMT_M_ONBOARD_NO_ADM: 2001,
    RC_E_ADM_JMT_M_ONBOARD_VERIFY_FAILED: 2002,
    RC_E_ADM_JMT_M_ONBOARD_ADM_EXISTS: 2003,
    RC_E_ADM_JMT_M_ONBOARD_BAD_ORIGIN_SITE: 2006,
    RC_E_ADM_JMT_M_ONBOARD_MIN_FULLNAME: 2007,
    RC_E_ADM_JMT_M_ONBOARD_MAX_FULLNAME: 2008,
    RC_E_ADM_JMT_M_ONBOARD_BAD_FULLNAME: 2009,
    RC_E_ADM_JMT_M_ONBOARD_PW_WEAK: 2011,
    RC_E_ADM_JMT_M_ONBOARD_EMAIL_BAD_GENERIC: 2012,
    RC_E_ADM_JMT_M_ONBOARD_EMAIL_BAD_SYNTAX: 2013,
    RC_E_ADM_JMT_M_ONBOARD_EMAIL_DISPOSABLE: 2014,
    RC_E_ADM_JMT_M_ONBOARD_EMAIL_NO_MAILBOX: 2015,

    // POST /_/api/adm/v1/jmt/m/unreg
    RC_ADM_JMT_M_UNREG: 1000,

    // GET /_/api/adm/v1/u/search
    RC_ADM_U_SEARCH: 1000,

    // POST /_/api/adm/v1/jmt/qna/create
    RC_ADM_JMT_QNA_CREATE: 1000,

    // POST /_/api/adm/v1/jmt/qna/update
    RC_ADM_JMT_QNA_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/qna/delete
    RC_ADM_JMT_QNA_DEL: 1000,

    // GET /_/api/v1/mcloud_acct/u/search
    RC_MCLOUD_ACCT_U_SEARCH: 1000,

    // GET /_/api/emailv/v1/sv/stats
    RC_EMAILV_SV_USAGE_STAT: 1000,

    // GET /_/api/blog/v1/post
    RC_BLOG_POST_GET: 1000,
    RC_E_BLOG_POST_GET_INVALID_POST_ID: 2000,

    // POST /_/api/blog/v1/post_comment
    RC_BLOG_POST_COMMENT: 1000,

    // POST /_/api/blog/v1/post_comment/upd
    RC_BLOG_POST_COMMENT_UPDATE: 1000,

    // DELETE /_/api/blog/v1/post_comment
    RC_BLOG_POST_COMMENT_DELETE: 1000,

    // POST /_/api/blog/v1/post_like
    RC_BLOG_POST_LIKE: 1000,

    // GET /_/api/blog/v1/post_list
    RC_BLOG_POST_LIST: 1000,

    // POST /_/api/blog/v1/post_add
    RC_BLOG_POST_ADD: 1000,
    RC_E_BLOG_POST_ADD_AUTHOR_REQUIRED: 2001,
    RC_E_BLOG_POST_ADD_INVALID_TAG_ID: 2001,
    RC_E_BLOG_POST_ADD_TITLE_ALREADY_EXISTS: 2002,

    // POST /_/api/blog/v1/tag_add
    RC_BLOG_TAG_ADD: 1000,
    RC_E_BLOG_TAG_ADD_NAME_ALREADY_EXISTS: 2000,

    // POST /_/api/blog/v1/post_bookmark
    RC_BLOG_POST_BOOKMARK: 1000,

    // POST /_/api/blog/v1/post_bookmark_list
    RC_BLOG_POST_BOOKMARK_LIST: 1000,

    // DELETE /_/api/blog/v1/tag
    RC_BLOG_TAG_DELETE: 1000,

    // POST /_/api/blog/v1/tag_upd
    RC_BLOG_TAG_UPDATE: 1000,
    RC_E_BLOG_TAG_UPDATE_NAME_ALREADY_EXISTS: 2000,

    // GET /_/api/blog/v1/tag_list
    RC_BLOG_TAG_LIST: 1000,

    // GET /_/api/blog/v1/search
    RC_BLOG_SEARCH: 1000,

    // DELETE /_/api/blog/v1/post
    RC_BLOG_POST_DELETE: 1000,
    RC_E_BLOG_POST_DELETE_AUTHOR_REQUIRED: 2000,

    // POST /_/api/blog/v1/post_upd
    RC_BLOG_POST_UPDATE: 1000,
    RC_E_BLOG_POST_UPDATE_AUTHOR_REQUIRED: 2000,
    RC_E_BLOG_POST_UPDATE_TITLE_ALREADY_EXISTS: 2001,

    // GET /_/api/blog/v1/popular_list
    RC_BLOG_POST_POPULAR_LIST: 1000,

    // POST /_/api/blog/v1/author_create
    RC_BLOG_AUTHOR_CREATE: 1000,
    RC_E_BLOG_AUTHOR_CREATE_AUTHOR_ALREADY_EXISTS: 2000,

    // POST /_/api/blog/v1/author_upd
    RC_BLOG_AUTHOR_UPDATE: 1000,

    // GET /_/api/blog/v1/author_details
    RC_BLOG_AUTHOR_DETAILS: 1000,
    RC_BLOG_AUTHOR_DETAILS_INVALID_AUTHOR_ID: 2000,

    // DELETE /_/api/blog/v1/author
    RC_BLOG_AUTHOR_DELETE: 1000,

    // GET /_/api/blog/v1/author_list
    RC_BLOG_AUTHOR_LIST: 1000,

    // GET /_/api/jmt/v1/feed
    RC_JMT_FEED: 1000,

    // GET /_/api/jmt/v1/m/search
    RC_JMT_M_SEARCH: 1000,

    // GET /_/api/jmt/v1/city/search
    RC_JMT_CITY_SEARCH: 1000,

    // GET /_/api/jmt/v1/qna/search
    RC_JMT_QNA_SEARCH: 1000,

    // GET /_/api/jmt/v1/qna/list
    RC_JMT_QNA_LIST: 1000,

    // GET /_/api/jmt/v1/qna/details?id=<uuid>
    RC_JMT_QNA_DETAILS: 1000,

    // GET /_/api/jmt/v1/m/nearby
    RC_JMT_NEARBY_M: 1000,

    //GET /_/api/jmt/v1/m/req/list
    RC_JMT_M_REQ_LIST: 1000,

    // GET /_/api/jmt/v1/m/invite_list
    RC_JMT_M_INVITE_LIST: 1000,
    RC_JMT_M_INVITE_LIST_NO_INVITE_EXISTS: 1001,

    // POST /_/api/jmt/v1/m/invite
    RC_JMT_M_INVITE: 1000,

    // GET /_/api/jmt/v1/m/invite_details
    RC_JMT_M_INVITE_DETAILS: 1000,

    // POST /_/api/jmt/v1/m/invite_accept
    RC_JMT_M_INVITE_ACCEPT: 1000,
    RC_E_JMT_M_INVITE_ACCEPT_USER_NOT_EXISTS: 2000,
    RC_E_JMT_M_INVITE_ACCEPT_INVITE_NOT_EXISTS: 2001,

    // POST /_/api/jmt/v1/m/invite_reject
    RC_JMT_M_INVITE_REJECT: 1000,
    RC_E_JMT_M_INVITE_REJECT_INVITE_NOT_EXISTS: 2000,

    // POST /_/api/jmt/v1/m/reg/form
    RC_JMT_M_REG_FORM: 1000,

    // GET /_/api/adm/v1/jmt/m/form
    RC_ADM_JMT_M_FORM: 1000,

    // GET /_/api/adm/v1/jmt/m/form/list
    RC_ADM_JMT_M_FORM_LIST: 1000,

    // GET /_/api/adm/v1/jmt/m/coordinates/exists
    RC_ADM_JMT_M_COORDINATES_EXISTS: 1000,

    // POST /_/api/adm/v1/jmt/m/form/accept
    RC_JMT_M_FORM_ACCEPT: 1000,
    RC_E_JMT_MOSQUE_ALREADY_REG: 2000,

    // POST /_/api/adm/v1/jmt/m/form/reject
    RC_JMT_M_FORM_REJECT: 1000,

    // GET /_/api/jmt/v1/m/details
    RC_JMT_M_DETAILS: 1000,

    // GET /_/api/jmt/v1/m/admin/list
    RC_JMT_M_ADMIN_LIST: 1000,

    // GET /_/api/jmt/v1/m/admin/del
    RC_JMT_M_ADMIN_DEL: 1000,

    // GET /_/api/jmt/v1/m/sub
    RC_JMT_SUB_M: 1000,

    // POST /_/api/jmt/v1/m/subscribe
    RC_JMT_M_SUBSCRIBE: 1000,
    RC_JMT_M_SUBSCRIBE_EXISTS: 1001,
    RC_JMT_M_SUBSCRIBE_NOT_REGISTERED: 1002,

    // POST /_/api/jmt/v1/m/unsubscribe
    RC_JMT_M_UNSUBSCRIBE: 1000,

    // POST /_/api/jmt/v1/m/set_home
    RC_JMT_M_SET_HOME: 1000,
    RC_JMT_M_SET_HOME_NOT_REGISTERED: 1001,

    // POST /_/api/jmt/v1/m/unset_home
    RC_JMT_M_UNSET_HOME: 1000,

    // GET /_/api/jmt/v1/m/feed
    RC_JMT_M_FEED: 1000,

    // GET /_/api/jmt/v1/hadith/collection
    RC_JMT_HADITH_COLLECTION: 1000,

    // GET /_/api/jmt/v1/hadith/book
    RC_JMT_HADITH_BOOK: 1000,

    // GET /_/api/jmt/v1/hadith/data
    RC_JMT_HADITH_DATA: 1000,

    // GET /_/api/jmt/v1/hadith/random
    RC_JMT_RANDOM_HADITH: 1000,

    // GET /_/api/jmt/v1/hadith/random/list
    RC_JMT_RANDOM_HADITH_LIST: 1000,

    // GET /_/api/jmt/v1/hadith/search
    RC_JMT_HADITH_SEARCH: 1000,

    // GET /_/api/adm/v1/jmt/hadith/collection/insert
    RC_ADM_JMT_HADITH_COLLECTION_INSERT: 1000,

    // GET /_/api/adm/v1/jmt/hadith/collection/upd
    RC_ADM_JMT_HADITH_COLLECTION_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/hadith/collection/delete
    RC_ADM_JMT_HADITH_COLLECTION_DEL: 1000,

    // GET /_/api/adm/v1/jmt/hadith/book/insert
    RC_ADM_JMT_HADITH_BOOK_INSERT: 1000,

    // GET /_/api/adm/v1/jmt/hadith/book/upd
    RC_ADM_JMT_HADITH_BOOK_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/hadith/book/delete
    RC_ADM_JMT_HADITH_BOOK_DEL: 1000,

    // GET /_/api/adm/v1/jmt/hadith/insert
    RC_ADM_JMT_HADITH_INSERT: 1000,

    // GET /_/api/adm/v1/jmt/hadith/upd
    RC_ADM_JMT_HADITH_UPDATE: 1000,

    // DELETE /_/api/adm/v1/jmt/hadith/delete
    RC_ADM_JMT_HADITH_DEL: 1000,

    // POST /_/api/jmt/v1/m/set_prayer_notif
    RC_JMT_M_SET_PRAYER_NOTIF: 1000,

    // POST /_/api/jmt/v1/prayer_time/download
    RC_PRAYER_TIME_TABLE_DOWNLOAD: 1000,
    RC_E_PRAYER_TIME_TABLE_DOWNLOAD: 2000,

    // POST /_/api/jmt/v1/names_of_allah/download
    RC_NAME_OF_ALLAH_TABLE_DOWNLOAD: 1000,
    RC_E_NAME_OF_ALLAH_TABLE_DOWNLOAD: 2000,

    // POST /_/api/jmt/v1/m/set_iqama_notif
    RC_JMT_M_SET_IQAMA_NOTIF: 1000,
    RC_JMT_M_SET_IQAMA_NOTIF_NOT_REGISTERED: 1001,

    // GET /_/api/jmt/v1/adm/status
    RC_JMT_ADM_STATUS: 1000,

    // GET /_/api/jmt/v1/adm/dashboard
    RC_JMT_ADM_DASHBOARD: 1000,

    // POST /_/api/jmt/v1/adm/m/iqama_upd
    RC_JMT_ADM_M_IQAMA_UPD: 1000,

    // GET // /_/api/jmt/v1/incorrect_iqama_timings/list
    RC_JMT_INCORRECT_IQAMA_TIMINGS_LIST: 1000,

    // POST /_/api/jmt/v1/incorrect_iqama_timings
    RC_JMT_INCORRECT_IQAMA_TIMINGS: 1000,

    // POST /_/api/jmt/v1/incorrect_maghrib_offset
    RC_JMT_INCORRECT_MAGHRIB_OFFSET: 1000,

    // POST /_/api/jmt/v1/incorrect_iqama_complaint_reject
    RC_JMT_INCORRECT_IQAMA_COMPLAINT_REJECT: 1000,

    // POST /_/api/jmt/v1/general_complaint
    RC_JMT_ADM_GENERAL_COMPLAINT: 1000,

    // GET // /_/api/adm/v1/jmt/general_complaint/list
    RC_ADM_JMT_GENERAL_COMPLAINT_LIST: 1000,

    // GET // /_/api/adm/v1/jmt/general_complaint
    RC_ADM_JMT_GENERAL_COMPLAINT_FORM: 1000,

    // POST /_/api/jmt/v1/adm/m/maghrib_offset_upd
    RC_JMT_ADM_M_MAGHRIB_OFFSET_UPD: 1000,

    // POST /_/api/jmt/v1/adm/m/metadata_upd
    RC_JMT_ADM_M_METADATA_UPD: 1000,

    // POST /_/api/jmt/v1/adm/m/announcements
    RC_JMT_ADM_M_ANNOUNCEMENT: 1000,

    // GET /_/api/jmt/v1/adm/m/announcements_list
    RC_JMT_ADM_M_ANNOUNCEMENTS_LIST: 1000,

    // POST /_/api/jmt/v1/adm/m/profile_upd
    RC_JMT_ADM_M_PROFILE_UPD: 1000,

    // GET /_/api/jmt/v1/quran/info/mushaf
    RC_JMT_QURAN_INFO_MUSHAF: 1000,

    // GET /_/api/jmt/v1/quran/info/juz
    RC_JMT_QURAN_INFO_JUZ: 1000,

    // GET /_/api/jmt/v1/quran/info/chapter
    RC_JMT_QURAN_INFO_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/info/ruku
    RC_JMT_QURAN_INFO_RUKU: 1000,

    // GET /_/api/jmt/v1/quran/info/hizb
    RC_JMT_QURAN_INFO_HIZB: 1000,

    // GET /_/api/jmt/v1/quran/info/manzil
    RC_JMT_QURAN_INFO_MANZIL: 1000,

    // GET /_/api/jmt/v1/quran/info/sajda
    RC_JMT_QURAN_INFO_SAJDA: 1000,

    // GET /_/api/jmt/v1/quran/verse/juz
    RC_JMT_QURAN_VERSE_JUZ: 1000,

    // GET /_/api/jmt/v1/quran/verse/chapter
    RC_JMT_QURAN_VERSE_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/verse/ruku
    RC_JMT_QURAN_VERSE_RUKU: 1000,

    // GET /_/api/jmt/v1/quran/verse/hizb
    RC_JMT_QURAN_VERSE_HIZB: 1000,

    // GET /_/api/jmt/v1/quran/verse/key
    RC_JMT_QURAN_VERSE_KEY: 1000,

    // GET /_/api/jmt/v1/quran/verse/random
    RC_JMT_QURAN_VERSE_RANDOM: 1000,

    // GET /_/api/jmt/v1/quran/verse/breakdown
    RC_JMT_QURAN_VERSE_BREAKDOWN: 1000,

    // GET /_/api/jmt/v1/quran/verse/detail
    RC_JMT_QURAN_VERSE_DETAIL: 1000,

    // GET /_/api/jmt/v1/quran/translation/list
    RC_JMT_QURAN_TRANSLATION_LIST: 1000,

    // GET /_/api/jmt/v1/quran/translation/verse
    RC_JMT_QURAN_TRANSLATION_VERSE: 1000,

    // GET /_/api/jmt/v1/quran/translation/chapter
    RC_JMT_QURAN_TRANSLATION_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/translation/juz
    RC_JMT_QURAN_TRANSLATION_JUZ: 1000,

    // GET /_/api/jmt/v1/quran/tafseer/list
    RC_JMT_QURAN_TAFSEER_LIST: 1000,

    // GET /_/api/jmt/v1/quran/tafseer/verse
    RC_JMT_QURAN_TAFSEER_VERSE: 1000,

    // GET /_/api/jmt/v1/quran/tafseer/chapter
    RC_JMT_QURAN_TAFSEER_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/tafseer/juz
    RC_JMT_QURAN_TAFSEER_JUZ: 1000,

    // GET /_/api/jmt/v1/quran/topic/list
    RC_JMT_QURAN_TOPIC_LIST: 1000,

    // GET /_/api/jmt/v1/quran/topic/sub_topic_list
    RC_JMT_QURAN_TOPIC_SUB_TOPIC_LIST: 1000,

    // GET /_/api/jmt/v1/quran/topic/details
    RC_JMT_QURAN_TOPIC_DETAIL: 1000,

    // GET /_/api/jmt/v1/quran/glyph/ruku
    RC_JMT_QURAN_GLYPH_RUKU: 1000,

    // GET /_/api/jmt/v1/quran/glyph/hizb
    RC_JMT_QURAN_GLYPH_HIZB: 1000,

    // GET /_/api/jmt/v1/quran/glyph/chapter
    RC_JMT_QURAN_GLYPH_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/glyph/juz
    RC_JMT_QURAN_GLYPH_JUZ: 1000,

    // GET /_/api/jmt/v1/quran/recitation/list
    RC_JMT_QURAN_RECITATION_LIST: 1000,

    // GET /_/api/jmt/v1/quran/recitation/chapter
    RC_JMT_QURAN_RECITATION_CHAPTER: 1000,

    // GET /_/api/jmt/v1/quran/streak/info
    RC_JMT_QURAN_STREAK_INFO: 1000,
    RC_JMT_QURAN_STREAK_INFO_ROW_NOT_FOUND: 2000,

    // GET /_/api/jmt/v1/quran/goal/info
    RC_JMT_QURAN_GOAL_INFO: 1000,
    RC_JMT_QURAN_GOAL_INFO_ROW_NOT_FOUND: 2000,

    // POST /_/api/jmt/v1/quran/streak/update
    RC_JMT_QURAN_STREAK_UPDATE: 1000,

    // POST /_/api/jmt/v1/quran/goal/update
    RC_JMT_QURAN_GOAL_UPDATE: 1000,

    // POST /_/api/hrm/v1/init
    RC_HRM_INIT: 1000,

    // POST /_/api/hrm/v1/m/tmpl/create
    RC_HRM_M_TMPL_CREATE: 1000,

    // GET /_/api/hrm/v1/m/get
    RC_HRM_M_GET: 1000,

    // POST /_/api/hrm/v1/m/create
    RC_HRM_M_CREATE: 1000,

    // POST /_/api/hrm/v1/m/field/add
    RC_HRM_M_FIELD_ADD: 1000,

    // POST /_/api/hrm/v1/m/field/upd
    RC_HRM_M_FIELD_UPD: 1000,

    // DELETE /_/api/hrm/v1/m/field/rm
    RC_HRM_M_FIELD_RM: 1000,

    // POST /_/api/hrm/v1/grp/m/add
    RC_HRM_GRP_M_ADD: 1000,

    // DELETE /_/api/hrm/v1/grp/m/rmv
    RC_HRM_GRP_M_RMV: 1000,

    // DELETE /_/api/hrm/v1/grp/tmpl/create
    RC_HRM_GRP_TMPL_CREATE: 1000,

    // DELETE /_/api/hrm/v1/grp/create
    RC_HRM_GRP_CREATE: 1000,

    // POST /_/api/emailv/v1/integ/create
    RC_EMAILV_INTEG_CREATE: 1000,
    RC_E_EMAILV_INTEG_CREATE_INVALID_CREDS: 2000,
    RC_E_EMAILV_INTEG_CREATE_ALREADY_EXISTS: 2001,

    // GET /_/api/emailv/v1/integ/await
    RC_EMAILV_INTEG_AWAIT: 1000,
    RC_E_EMAILV_INTEG_AWAIT_TIMEOUT: 2000,
    RC_E_EMAILV_INTEG_AWAIT_ALREADY_EXISTS: 2001,

    // GET /_/api/emailv/v1/integ/list
    RC_EMAILV_INTEG_LIST: 1000,

    // DELETE /_/api/emailv/v1/integ/del
    RC_EMAILV_INTEG_DEL: 1000,

    // GET /_/api/emailv/v1/integ/auth_url
    RC_EMAILV_INTEG_AUTH_URL: 1000,
    RC_E_EMAILV_INTEG_AUTH_URL_GENERIC: 2000,

    // GET /_/api/emailv/v1/integ/auth_token
    RC_EMAILV_INTEG_AUTH_TOKEN: 1000,
    RC_E_EMAILV_INTEG_AUTH_TOKEN_ALREADY_EXIST: 2000,
    RC_E_EMAILV_INTEG_AUTH_TOKEN_GENERIC: 2001,

    // POST /_/api/emailv/v1/integ/metadata
    RC_EMAILV_INTEG_METADATA: 1000,

    // GET /_/api/emailv/v1/integ/lists
    RC_EMAILV_INTEG_LISTS: 1000,

    // GET /_/api/emailv/v1/integ/lists/list_history
    RC_EMAILV_INTEG_LIST_HISTORY: 1000,
    RC_E_EMAILV_INTEG_LIST_HISTORY_NOT_FOUND: 2000,

    // GET /_/api/emailv/v1/integ/list_detail
    RC_EMAILV_INTEG_LIST_DETAIL: 1000,
    RC_E_EMAILV_INTEG_LIST_DETAIL_NOT_FOUND: 2000,

    // GET /_/api/emailv/v1/integ/lists/list_reload
    RC_EMAILV_INTEG_LIST_RELOAD: 1000,
    RC_E_EMAILV_INTEG_LIST_RELOAD_NOT_UPDATED: 2000,
    RC_E_EMAILV_INTEG_LIST_RELOAD_LIST_INFO: 2001,

    // POST /_/api/emailv/v1/integ/bv/create
    RC_EMAILV_INTEG_BV_CREATE: 1000,
    RC_E_EMAILV_INTEG_BV_CREATE_EMAILS_EXCEED: 2000,
    RC_E_EMAILV_INTEG_BV_CREATE_EMAILS_ZERO: 2001,
    RC_E_EMAILV_INTEG_BV_CREATE_IMPORT: 2002,
    RC_E_EMAILV_INTEG_BV_CREATE_LIST_INFO: 2003,

    // POST /_/api/emailv/v1/integ/lists/list_export
    RC_EMAILV_INTEG_LIST_EXPORT: 1000,
    RC_E_EMAILV_INTEG_LIST_EXPORT_GENERIC: 2000,
    RC_E_EMAILV_INTEG_LIST_EXPORT_ACTIVE_SUBS: 2001,
    RC_E_EMAILV_INTEG_LIST_EXPORT_OPTION_NOT_AVAILABLE: 2002,

    // GET /_/api/doc/v1/list
    RC_DOC_LIST: 1000,

    // GET /_/api/doc/v1/nav
    RC_DOC_NAV: 1000,
    RC_E_DOC_NAV_INVALID_DOC_UNIQUE_ID: 2000,

    // GET /_/api/doc/v1/page_get
    RC_DOC_PAGE_GET: 1000,
    RC_E_DOC_PAGE_GET_INVALID_URL: 2000,
    RC_E_DOC_PAGE_GET_INVALID_DOC_UNIQUE_ID: 2001,

    // POST /_/api/doc/v1/create
    RC_DOC_CREATE: 1000,
    RC_E_DOC_CREATE_EXISTS: 2000,

    // POST /_/api/doc/v1/update
    RC_DOC_UPDATE: 1000,
    RC_DOC_UPDATE_INVALID_DOC_ID: 1001,
    RC_E_DOC_UPDATE_NAME_OR_UNIQUE_ID_EXISTS: 2000,

    // DELETE /_/api/doc/v1/del
    RC_DOC_DELETE: 1000,
    RC_E_DOC_DELETE_INVALID_DOC_UNIQUE_ID: 2000,

    // POST /_/api/doc/v1/page_create
    RC_DOC_PAGE_CREATE: 1000,
    RC_E_DOC_PAGE_CREATE_PARENT_NOT_FOUND: 2000,
    RC_E_DOC_PAGE_CREATE_URL_ALREADY_EXISTS: 2001,
    RC_E_DOC_PAGE_CREATE_INVALID_DOC_UNIQUE_ID: 2002,

    // GET /_/api/dnsbl/v1/check
    RC_DNSBL_CHECK: 1000,
    RC_E_DNSBL_CHECK_INVALID_HOST: 2000,

    // GET /_/api/dnsbl/v1/list
    RC_DNSBL_LIST: 1000,
    RC_E_DNSBL_LIST_INVALID_HOST: 2000,

    // GET /_/api/dnsbl_monitor/v1/host
    RC_DNSBL_MONITOR_HOST: 1000,
    RC_DNSBL_MONITOR_HOST_NOT_EXISTS: 2000,
    RC_E_DNSBL_MONITOR_HOST_ACCOUNT_BAN: 2001,

    // GET /_/api/dnsbl_monitor/v1/stat
    RC_DNSBL_MONITOR_STAT: 1000,
    RC_E_DNSBL_MONITOR_STAT_NOT_ENABLED: 2000,

    // POST /_/api/dnsbl_monitor/v1/host_add
    RC_DNSBL_MONITOR_HOST_ADD: 1000,
    RC_E_DNSBL_MONITOR_HOST_ADD_MONITOR_NOT_EXISTS: 2000,
    RC_E_DNSBL_MONITOR_HOST_ADD_HOST_ALREADY_EXISTS: 2001,
    RC_E_DNSBL_MONITOR_HOST_ADD_BOGON_IP: 2002,
    RC_E_DNSBL_MONITOR_HOST_ADD_ACCOUNT_BAN: 2003,
    RC_E_DNSBL_MONITOR_HOST_USAGE_LIMIT_REACHED: 2004,

    // POST /_/api/dnsbl_monitor/v1/host_upd
    RC_DNSBL_MONITOR_HOST_UPD: 1000,

    // POST /_/api/dnsbl_monitor/v1/host_provider_toggle_upd
    RC_DNSBL_MONITOR_HOST_TOGGLE_UPD: 1000,

    // GET /_/api/dnsbl_monitor/v1/hosts_list
    RC_DNSBL_MONITOR_HOSTS_LIST: 1000,
    RC_E_DNSBL_MONITOR_HOSTS_LIST_NOT_EXISTS: 2000,
    RC_E_DNSBL_MONITOR_HOSTS_LIST_ACCOUNT_BAN: 2001,

    // DELETE /_/api/dnsbl_monitor/v1/host
    RC_DNSBL_MONITOR_HOST_DEL: 1000,

    // DELETE /_/api/mcloud/v1/apikey_del
    RC_MCLOUD_APIKEY_DELETE: 1000,

    // GET /_/api/mcloud/v1/apikey_gen
    RC_MCLOUD_APIKEY_GENERATE: 1000,

    // GET /_/api/mcloud/v1/apikey_list
    RC_MCLOUD_APIKEY_LIST: 1000,

    // GET /_/api/mcloud/v1/company_info
    RC_MCLOUD_COMPANY_INFO: 1000,
    RC_E_MCLOUD_COMPANY_INFO_USER_NOT_EXISTS: 2000,
    RC_E_MCLOUD_COMPANY_INFO_URL_NOT_VALID: 2001,
    RC_E_MCLOUD_COMPANY_INFO_PHONE_NOT_VALID: 2002,

    // POST /_/api/mcloud/v1/set_company_img
    RC_MCLOUD_SET_COMPANY_IMG: 1000,

    // POST /_/api/mcloud/v1/u_update
    RC_MCLOUD_U_UPDATE: 1000,

    // DELETE /_/api/mcloud/v1/u_rm
    RC_MCLOUD_U_RM: 1000,
    RC_E_MCLOUD_U_RM_USER_NOT_OWNER: 2000,
    RC_E_MCLOUD_U_RM_USER_NOT_EXISTS: 2001,

    // GET /_/api/mcloud/v1/u_acct_list
    RC_MCLOUD_U_ACCT_LIST: 1000,

    // GET /_/api/mcloud/v1/u_list
    RC_MCLOUD_ACCT_U_LIST: 1000,

    // POST /_/api/mcloud/v1/u_acct_switch
    RC_MCLOUD_U_ACCT_SWITCH: 1000,
    RC_E_MCLOUD_U_ACCT_SWITCH_ACCT_U_NOT_EXITS: 2001,

    // POST /_/api/mcloud/v1/acct_pass_ownership
    RC_MCLOUD_ACCT_PASS_OWNERSHIP: 1000,
    RC_E_MCLOUD_ACCT_PASS_OWNERSHIP_USER_NOT_EXISTS: 2000,
    RC_E_MCLOUD_ACCT_PASS_OWNERSHIP_USER_NOT_OWNER: 2001,

    // GET /_/api/\mcloud/v1/permission_list
    RC_MCLOUD_PERMISSIONS_LIST: 1000,

    // GET /_/api/mcloud/v1/acct_invites_list
    RC_MCLOUD_ACCT_INVITE_LIST: 1000,

    // POST /_/api/mcloud/v1/acct_verify
    RC_MCLOUD_ACCT_VERIFY: 1000,
    RC_E_MCLOUD_ACCT_VERIFY_USER_NOT_OWNER: 2000,
    RC_E_MCLOUD_ACCT_VERIFY_NO_ACCT: 2001,

    // POST /_/api/doc/v1/page_save
    RC_DOC_PAGE_SAVE: 1000,
    RC_E_DOC_PAGE_SAVE_PAGE_URL_ALREADY_EXISTS: 2000,
    RC_E_DOC_PAGE_SAVE_INVALID_DOC_UNIQUE_ID: 2001,

    // DELETE /_/api/doc/v1/page_delete
    RC_DOC_PAGE_DELETE: 1000,
    RC_E_DOC_PAGE_DELETE_INVALID_DOC_UNIQUE_ID: 2000,

    // GET /_/api/sms_otp/v1/service/list
    RC_SMS_OTP_SERVICE_LIST: 1000,

    // GET /_/api/sms_otp/v1/service
    RC_SMS_OTP_SERVICE_DETAIL: 1000,

    // POST /_/api/sms_otp/v1/service/create
    RC_SMS_OTP_SERVICE_CREATE: 1000,

    // POST /_/api/sms_otp/v1/service/upd
    RC_SMS_OTP_SERVICE_UPDATE: 1000,

    // DELETE /_/api/sms_otp/v1/service/del
    RC_SMS_OTP_SERVICE_DELETE: 1000,

    // POST /_/api/sms_otp/v1/init
    RC_SMS_OTP_INIT: 1000,
    RC_E_SMS_OTP_INIT_OTP_ALREADY_EXISTS: 2000,

    // POST /_/api/inbox_placement_test/v1/enable
    RC_INBOX_PLACEMENT_TEST_ENABLE: 1000,
    RC_E_INBOX_PLACEMENT_TEST_ENABLE_ALREADY_EXISTS: 2000,

    // POST /_/api/inbox_placement_test/v1/create
    RC_INBOX_PLACEMENT_TEST_CREATE: 1000,
    RC_E_INBOX_PLACEMENT_TEST_USAGE_LIMIT_REACHED: 2000,

    // GET /_/api/inbox_placement_test/v1/list
    RC_INBOX_PLACEMENT_TEST_LIST: 1000,
    RC_E_INBOX_PLACEMENT_TEST_LIST_NOT_EXIST: 2000,

    // DELETE /_/api/inbox_placement_test/v1/delete
    RC_INBOX_PLACEMENT_TEST_DELETE: 1000,

    // GET /_/api/inbox_placement_test/v1/stats
    RC_INBOX_PLACEMENT_TEST_STATS: 1000,
    RC_E_INBOX_PLACEMENT_TEST_STATS_NOT_EXIST: 2000,

    // POST /_/api/email_server_test/v1/enable
    RC_EMAIL_SERVER_TEST_ENABLE: 1000,
    RC_E_EMAIL_SERVER_TEST_ENABLE_ALREADY_EXISTS: 2000,

    // POST /_/api/email_server_test/v1/create
    RC_EMAIL_SERVER_TEST_CREATE: 1000,
    RC_E_EMAIL_SERVER_TEST_USAGE_LIMIT_REACHED: 2000,

    // GET /_/api/email_server_test/v1/list
    RC_EMAIL_SERVER_TEST_LIST: 1000,
    RC_E_EMAIL_SERVER_TEST_LIST_NOT_EXIST: 2000,

    // GET /_/api/mx/v1/host_check
    RC_MX_HOST_CHECK: 1000,
    RC_E_MX_HOST_CHECK_RECORD_NOT_EXIST: 2000,

    // DELETE /_/api/email_server_test/v1/delete
    RC_EMAIL_SERVER_TEST_DELETE: 1000,

    // POST /_/api/media/v1/process
    RC_MEDIA_PROCESS: 1000,
    RC_E_MEDIA_PROCESS_FILE_ID_NOT_EXIST: 2000,

    // GET /_/api/media/v1/details
    RC_MEDIA_DETAILS: 1000,
    RC_E_MEDIA_DETAILS_INVALID_MEDIA_ID: 2000,

    // POST /_/api/markdown/preview
    RC_MARKDOWN_PREVIEW: 1000,

    // POST /_/api/crms/v1/enable
    RC_CRMS_ENABLE: 1000,
    RC_E_CRMS_ENABLE_ALREADY_EXISTS: 2000,

    // POST /_/api/crms/v1/field/create
    RC_CRMS_FIELD_CREATE: 1000,

    // POST /_/api/crms/v1/field/update
    RC_CRMS_FIELD_UPDATE: 1000,

    // GET /_/api/crms/v1/field
    RC_CRMS_FIELD_GET: 1000,

    // GET /_/api/crms/v1/field/list
    RC_CRMS_FIELD_LIST: 1000,

    // GET /_/api/crms/v1/field/obj_field_list
    RC_CRMS_FIELD_OBJECT_FIELD_LIST: 1000,

    // POST /_/api/crms/v1/field/attach
    RC_CRMS_FIELD_ATTACH: 1000,

    // DELETE /_/api/crms/v1/field
    RC_CRMS_FIELD_DELETE: 1000,

    // GET /_/api/crms/v1/contacts
    RC_CRMS_CONTACTS: 1000,

    // POST /_/api/crms/v1/contacts/create
    RC_CRMS_CONTACTS_CREATE: 1000,
    RC_E_CRMS_CONTACTS_CREATE_EXISTS: 2000,

    // POST /_/api/crms/v1/contacts/upload
    RC_CRMS_CONTACTS_UPLOAD: 1000,
    RC_E_CRMS_CONTACTS_UPLOAD: 2000,
    RC_E_CRMS_CONTACTS_UPLOAD_MALFORMED: 2001,
    RC_E_CRMS_CONTACTS_UPLOAD_INVALID_HEADERS: 2002,

    // DELETE /_/api/crms/v1/contacts/del
    RC_CRMS_CONTACTS_DELETE: 1000,

    // POST /_/api/crms/v1/contacts/upd
    RC_CRMS_CONTACTS_UPDATE: 1000,
    RC_E_CRMS_CONTACTS_UPDATE_EXISTS: 2000,

    // GET /_/api/crms/v1/contact_list
    RC_CRMS_CONTACT_LIST: 1000,

    // POST /_/api/crms/v1/contact_list/create
    RC_CRMS_CONTACT_LIST_CREATE: 1000,
    RC_E_CRMS_CONTACT_LIST_CREATE_NAME_EXISTS: 2000,

    // POST /_/api/crms/v1/contact_list/contacts_add
    RC_CRMS_CONTACT_LIST_CONTACTS_ADD: 1000,
    RC_E_CRMS_CONTACT_LIST_CONTACTS_ADD: 2000,
    RC_E_CRMS_CONTACT_LIST_CONTACTS_ADD_MALFORMED: 2001,
    RC_E_CRMS_CONTACT_LIST_CONTACTS_ADD_INVALID_HEADERS: 2002,

    // POST /_/api/crms/v1/contact_list/contact_create
    RC_CRMS_CONTACT_LIST_CONTACT_CREATE: 1000,
    RC_E_CRMS_CONTACT_LIST_CONTACT_CREATE_EXISTS: 2000,

    // POST /_/api/crms/v1/contact_list/contacts_remove
    RC_CRMS_CONTACT_LIST_CONTACTS_REMOVE: 1000,

    // POST /_/api/crms/v1/contact_list/duplicate
    RC_CRMS_CONTACT_LIST_DUPLICATE: 1000,
    RC_E_CRMS_CONTACT_LIST_DUPLICATE_NAME_EXISTS: 2000,

    // POST /_/api/crms/v1/contact_list/upd
    RC_CRMS_CONTACT_LIST_UPDATE: 1000,
    RC_E_CRMS_CONTACT_LIST_UPDATE_NAME_EXISTS: 2000,

    // GET /_/api/crms/v1/contact_list/details
    RC_CRMS_CONTACT_LIST_DETAILS: 1000,
    RC_CRMS_CONTACT_LIST_DETAILS_INVALID_LIST_ID: 2000,

    // DELETE /_/api/crms/v1/contact_list/del
    RC_CRMS_CONTACT_LIST_DELETE: 1000,

    // GET /_/api/crms/v1/dedicated_ip/list
    RC_CRMS_DEDICATED_IP_LIST: 1000,

    // POST /_/api/crms/v1/dedicated_ip/upd
    RC_CRMS_DEDICATED_IP_UPD: 1000,

    // POST /_/api/crms_campaign/v1/create
    RC_CRMS_CAMPAIGN_CREATE: 1000,
    RC_E_CRMS_EMAIL_CAMPAIGN_USAGE_LIMIT_REACHED: 2000,
    RC_E_CRMS_WHATSAPP_CAMPAIGN_USAGE_LIMIT_REACHED: 2001,

    // POST /_/api/crms_campaign/v1/delete
    RC_CRMS_CAMPAIGN_DELETE: 1000,

    // GET /_/api/crms/v1/campaign/contacts
    RC_CRMS_CAMPAIGN_CONTACTS: 1000,

    // POST /_/api/crms/v1/campaign/status_upd
    RC_CRMS_CAMPAIGN_STATUS_UPD: 1000,
    RC_CRMS_CAMPAIGN_STATUS_UPD_INVALID_STATUS: 2000,

    // GET /_/api/crms_campaign/v1/get_types
    RC_CRMS_CAMPAIGN_GET_TYPES: 1000,

    // GET /_/api/crms/v1/campaign/stats
    RC_CRMS_CAMPAIGN_STATS: 1000,

    // POST /_/api/crms_campaign/v1/email_tmpl_bind
    RC_CRMS_CAMPAIGN_EMAIL_TMPL_BIND: 1000,

    // GET /_/api/crms/v1/campaign/list
    RC_CRMS_CAMPAIGN_LIST: 1000,

    // POST /_/api/crms/v1/email_integ/create
    RC_CRMS_EMAIL_INTEG_CREATE: 1000,
    RC_E_CRMS_EMAIL_INTEG_CREATE_DUPLICATE: 2000,

    // POST /_/api/crms/v1/email_integ/verify
    RC_CRMS_INTEG_EMAIL_VERIFY: 1000,

    // GET /_/api/crms/v1/email_integ/list
    RC_CRMS_EMAIL_INTEG_LIST: 1000,

    // GET /_/api/crms/v1/email_integ/campaigns
    RC_CRMS_INTEG_EMAIL_CAMPAIGNS: 1000,

    // POST /_/api/crms/v1/email_integ/delete
    RC_CRMS_INTEG_EMAIL_DELETE: 1000,

    // POST /_/api/crms/v1/email_integ/get
    RC_CRMS_INTEG_EMAIL_GET: 1000,

    // POST /_/api/crms_tmpl_email/v1/img_upload
    RC_CRMS_TMPL_EMAIL_IMG_UPLOAD: 1000,

    // POST /_/api/crms_tmpl_email/v1/create
    RC_CRMS_TMPL_EMAIL_CREATE: 1000,

    // POST /_/api/crms_tmpl_email/v1/update
    RC_CRMS_TMPL_EMAIL_UPDATE: 1000,

    // POST /_/api/crms_tmpl_email/v1/list
    RC_CRMS_TMPL_EMAIL_LIST: 1000,

    // POST /_/api/crms_tmpl_email/v1/delete
    RC_CRMS_TMPL_EMAIL_DELETE: 1000,

    // POST /_/api/crms_integ_sms/v1/create
    RC_CRMS_INTEG_SMS_CREATE: 1000,

    // POST /_/api/crms_integ_sms/v1/update
    RC_CRMS_INTEG_SMS_UPDATE: 1000,

    // POST /_/api/crms_integ_sms/v1/delete
    RC_CRMS_INTEG_SMS_DELETE: 1000,

    // GET /_/api/crms_integ_sms/v1/list
    RC_CRMS_INTEG_SMS_LIST: 1000,

    // POST /_/api/crms_tmpl_sms/v1/create
    RC_CRMS_TMPL_SMS_CREATE: 1000,

    // POST /_/api/crms_tmpl_sms/v1/update
    RC_CRMS_TMPL_SMS_UPDATE: 1000,

    // POST /_/api/crms_tmpl_sms/v1/delete
    RC_CRMS_TMPL_SMS_DELETE: 1000,

    // GET /_/api/crms_tmpl_sms/v1/list
    RC_CRMS_TMPL_SMS_LIST: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/create
    RC_CRMS_TMPL_WHATSAPP_CREATE: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/update
    RC_CRMS_TMPL_WHATSAPP_UPDATE: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/delete
    RC_CRMS_TMPL_WHATSAPP_DELETE: 1000,

    // GET /_/api/crms_tmpl_whatsapp/v1/list
    RC_CRMS_TMPL_WHATSAPP_LIST: 1000,

    // GET /_/api/crms_tmpl_whatsapp/v1/get
    RC_E_CRMS_INVALID_TEMPLATE_ID: 2994,
    RC_CRMS_WHATSAPP_TMPL_GET: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/create
    RC_CRMS_WHATSAPP_INTEG_CREATE: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/update
    RC_CRMS_WHATSAPP_INTEG_UPDATE: 1000,

    // POST /_/api/crms_tmpl_whatsapp/v1/delete
    RC_CRMS_WHATSAPP_INTEG_DELETE: 1000,

    // GET /_/api/crms_tmpl_whatsapp/v1/list
    RC_CRMS_WHATSAPP_INTEG_LIST: 1000,

    // GET /_/api/crms_stats/v1/get
    RC_CRMS_STATS: 1000,

    // POST /_/api/crms/v1/domain_warmup/start
    RC_CRMS_DOMAIN_WARMUP_START: 1000,

    // POST /_/api/crms/v1/domain_warmup/details
    RC_CRMS_DOMAIN_WARMUP_DETAILS: 1000,
    RC_E_RMS_DOMAIN_WARMUP_DETAILS_NOT_FOUND: 2000,

    // GET /_/api/accounts/v1/isenabled
    RC_ACCOUNTS_ENABLED: 1000,

    // POST /_/api/mcloud/v1/parse_generic_file_data
    RC_DATA_PARSED_SUCCESSFULLY: 1000,

    // GET /_/api/mcloud/v1/acct/products_status
    RC_MCLOUD_ACCT_PRODUCTS_STATUS: 1000,

    // GET /_/api/wabil/v1/wallpapers/categories
    RC_WABIL_WALLPAPER_CATEGORIES: 1000,

    // GET /_/api/wabil/v1/wallpapers/sub_categories
    RC_WABIL_WALLPAPER_SUB_CATEGORIES: 1000,

    // GET /_/api/wabil/v1/wallpapers/featured_sub_categories
    RC_WABIL_WALLPAPER_FEATURED_SUB_CATEGORIES: 1000,

    // GET /_/api/wabil/v1/wallpapers/featured_images
    RC_WABIL_WALLPAPER_FEATURED_IMAGES: 1000,

    // POST /_/api/adm/v1/wabil/wallpaper/category/add
    RC_ADM_WABIL_WALLPAPER_CATEGORY_ADD: 1000,
    RC_ADM_WABIL_WALLPAPER_CATEGORY_UPD: 1000,
    RC_ADM_WABIL_WALLPAPER_CATEGORY_ALREADY_EXISTS: 2000,

    // POST /_/api/adm/v1/wabil/wallpaper/sub_category/add
    RC_ADM_WABIL_WALLPAPER_SUB_CATEGORY_ADD: 1000,
    RC_ADM_WABIL_WALLPAPER_SUB_CATEGORY_UPD: 1000,
    RC_ADM_WABIL_WALLPAPER_SUB_CATEGORY_ALREADY_EXISTS: 2000,

    // POST /_/api/adm/v1/wabil/wallpaper/images/add
    RC_ADM_WABIL_WALLPAPER_IMAGES_ADD: 1000,

    // GET /_/api/wabil/v1/wallpapers/images
    RC_ADM_WABIL_WALLPAPER_IMAGES: 1000,

    // POST /_/api/adm/v1/wabil/wallpaper/images/update
    RC_ADM_WABIL_WALLPAPER_IMAGE_UPD: 1000,

    // DELETE /_/api/adm/v1/wabil/wallpaper/category/delete
    RC_ADM_WABIL_WALLPAPER_CATEGORY_DEL: 1000,

    // DELETE /_/api/adm/v1/wabil/wallpaper/sub_category/delete
    RC_ADM_WABIL_WALLPAPER_SUB_CATEGORY_DEL: 1000,

    // DELETE /_/api/adm/v1/wabil/wallpaper/image/delete
    RC_ADM_WABIL_WALLPAPER_IMAGE_DEL: 1000,
};

declare global {
    var httpCodes: typeof svHttpCodes;
}

globalThis.httpCodes = svHttpCodes;

export default svHttpCodes;
